<template>
  <ClientOnly>
    <div class="pl-3 sm:pl-20">
      <Breadcrumb v-if="crumbs.length">
        <BreadcrumbList class="flex flex-nowrap overflow-x-auto">
          <BreadcrumbItem v-for="crumb in crumbs" :key="crumb.path" class="flex-shrink-0">
            <NuxtLink :to="crumb.path" :prefetch="false" class="whitespace-nowrap">
              <BreadcrumbLink class="capitalize">
                <p class="select-none truncate max-w-[100px] sm:max-w-[200px]">{{ getBreadcrumbTitle(crumb) }}</p>
              </BreadcrumbLink>
            </NuxtLink>
            <BreadcrumbSeparator v-if="crumb.path !== crumbs[crumbs.length - 1].path" />
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    </div>
  </ClientOnly>
</template>

<script setup>
import { apStyleTitleCase } from 'ap-style-title-case'
import { useProjectData } from '@/composables/projectData'

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const {selectedTeam, user} = storeToRefs(userStore)

const projectStore = useProjectStore()
const {selectedProject, projects} = storeToRefs(projectStore)

// const { data: projects } = useNuxtData(`projects-${route.params.team}`)

// console.log(route.params.team)


const crumbs = computed(() => {
  const fullPath = route.fullPath
  const params = fullPath.startsWith('/')
    ? fullPath.substring(1).split('/')
    : fullPath.split('/')
  const trail = []
  let path = ''
  params.forEach((param, index) => {
    if (param === '') return // Skip the root path
    path = `${path}/${param}`
    const match = router.resolve(path)
    if (match.name !== null) {
      let title = param.replace(/-/g, ' ')
      // Check if the current param matches the team param
      console.log(route.params?.team, selectedTeam?.value?.metadata)
      if (param === route.params?.team && selectedTeam?.value?.metadata) {
        title = selectedTeam.value.metadata.name
      }
      // Check if the current param matches any project ID
      if (projects.value?.length) {
        
        const project = projects.value.find(proj => proj.id == param)
        if (project) {
          title = project.title
        }
        
      } 
      // Check if the current param matches the user ID
      if (param === user.value?.id) {
        title = user.value.user_metadata.name
      }
      
      trail.push({
        title,
        path: match.path,
        route: match.name
      })
    }
  })
  return trail
})

const getBreadcrumbTitle = (crumb) => {
  if (crumb.route == 'type-team-project' && projects?.length && selectedProject) {
    return crumb.title;
  } else if (crumb.route == 'team') {
    return `${crumb.title}s`; 
  } else if (crumb.route == 'user') {
    return `Personal`;
  } 
  else {
    return crumb.title;
  }
};

</script>
