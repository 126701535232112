<script setup lang="ts">
import { TooltipProvider, TooltipPortal, TooltipRoot, TooltipTrigger, TooltipContent } from 'radix-vue'
import { ref, watch } from 'vue'

const props = defineProps<{
  content: string | { __html: string }
  side?: 'top' | 'right' | 'bottom' | 'left'
  align?: 'start' | 'center' | 'end'
  maxWidth?: string
  collisionBoundary?: Element | (Element | null)[] | null
  animate?: boolean
}>()

const isHtmlContent = (content: string | { __html: string }): content is { __html: string } => {
  return typeof content === 'object' && '__html' in content
}

const isOpen = ref(false)
const shouldRender = ref(false)

// Add this line to increase the z-index
const tooltipZIndex = 10001 // This should be higher than the dialog overlay z-index

watch(isOpen, (newValue) => {
  if (newValue) {
    shouldRender.value = true
  } else {
    setTimeout(() => {
      shouldRender.value = false
    }, 300) // Match this with the animation duration
  }
})
</script>

<template>
    <TooltipProvider :delayDuration="0" :skipDelayDuration="700">
        <TooltipRoot v-model:open="isOpen">
            <TooltipTrigger as-child>
                <slot />
            </TooltipTrigger>
            
            <TooltipPortal>
                <TooltipContent 
                    v-if="shouldRender"
                    :side="side" 
                    :align="align" 
                    :avoidCollisions="true" 
                    :collisionPadding="8"
                    :sideOffset="5" 
                    :sticky="true" 
                    :collisionBoundary="collisionBoundary"
                    class="z-20 bg-primary text-background select-none border-none p-2 rounded-lg transition-opacity duration-300 ease-in-out"
                    :class="[
                        animate ? 'translate-x-5 animate-in fade-in-0 zoom-in-95 translate-x-0' : '',
                        { 'opacity-0': !isOpen }
                    ]"
                    :style="{ 
                        maxWidth: maxWidth || '300px',
                        zIndex: tooltipZIndex // Add this line to set the z-index
                    }"
                >
                    <template v-if="isHtmlContent(content)">
                        <div class="z-20" v-html="content.__html"></div>
                    </template>
                    <template v-else>
                        {{ content }}
                    </template>
                </TooltipContent>
            </TooltipPortal>
        </TooltipRoot>
    </TooltipProvider>
</template>

<style scoped>
:deep(.v-popper__popper) {
  word-wrap: break-word;
}
</style>