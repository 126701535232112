<template>
  
  <div vaul-drawer-wrapper class="h-full bg-muted" ref="rootPageRef">
    <div class="absolute left-0 w-full top-[64px] h-[calc(100vh-64px)] w-full z-10 bg-primary">
      
    </div>
    <GlobalBanner />
    
    <NuxtLoadingIndicator />
    <div :class="['z-0 h-[100vh] relative z-10 overflow-x-hidden', {'pb-0': !route.params.team && ready}]">
      <ClientOnly> 
        <div v-if="user" class="z-10 relative">
          
          <NavbarHeader ref="navbarHeader" v-motion-fade class="flex justify-between w-full ">
            <template #menu>
              <!-- <NuxtLink to="/" class="w-8 h-8 aspect-square hidden sm:block">
                        <img src="/android-chrome-192x192.png" width="2rem" height="2rem" class="h-8 w-8" />
                    </NuxtLink> -->
              <div class="flex justify-between w-full">
                <NavbarMenu :menuItems="menu" />

                <ProTrialButton v-if="user && plan?.product?.name == 'Basic'" />
              </div>
            </template>

            <template #dropdownmenu>
              <NavbarDropdownMenu v-if="user" />

            </template>
          </NavbarHeader>
        </div>
      </ClientOnly>

      <NuxtLayout>
        <!-- <ClientOnly> -->
        <NuxtPage />
        <!-- </ClientOnly> -->

      </NuxtLayout>
    </div>

    <!--  TODO why does w-[100vw] overlap the scrollbars? -->
    <footer class="fixed bottom-0 w-full right-0 h-50 select-none pointer-events-none">
      <div class="flex flex-col h-64 justify-evenly">
        <div
          class="w-full mx-auto max-w-screen-xl p-4 px-4 md:px-16 md:flex md:items-center md:justify-between mb-0 pb-2 scale-75 origin-bottom-left sm:scale-100 bg-transparent">
          <span v-motion-fade :delay="800" class="text-xs sm:text-center text-accent">©2024 SuperSeeded</span>
          <ul v-motion-fade :delay="800" class="flex flex-wrap items-center mt-1 text-xs text-accent sm:mt-0">
            <!-- <li>
                              <a href="#" class="hover:underline me-4 md:me-6">About</a>
                          </li> -->
            <li>
              <a v-motion-fade :delay="800" href="/legal/privacy" class="hover:underline me-4 md:me-6">Privacy
                Policy</a>
            </li>
            <li>
              <a v-motion-fade :delay="800" href="/legal/tos" class="hover:underline me-4 md:me-6">Terms of Use</a>
            </li>
            <li></li>
          </ul>
        </div>
        <div v-motion-fade :delay="0"
          class="w-full mx-auto max-w-screen-xl px-4 md:px-16 md:flex md:items-center md:justify-between mb-0 text-md text-accent">
          We acknowledge all First Peoples of the beautiful lands on which we live
          and celebrate their enduring knowledge and connections to Country. We
          honour the wisdom of and pay respect to Elders past and present.
        </div>
      </div>
    </footer>
    <BottomDrawer />
    <ClientOnly>
      <SideDrawer v-if="windowWidth < 768" />
      <ReviewDrawer v-if="activePanel && reviewSideDrawerMode" :toggleSideDrawerMode="true" />
    </ClientOnly>



    <Notivue v-slot="item">
      <NotivueSwipe :item="item">
        <Notification class="notivue-custom" :item="item" :color="item.type"
          :theme="colorMode.value == 'light' ? mylightTheme : myDarkTheme" />
      </NotivueSwipe>
    </Notivue>

    <!-- Use a portal for the AlertDialog -->
    <ClientOnly>
      <Teleport to="body">
        <AlertDialog :open="showAlert" @update:open="showAlert">
          <AlertDialogContent class="-mt-[20vh] border-1 border-muted-foreground shadow-xxl ">
            <component :is="alertDialogDynamicComponent" v-if="alertConfig" />
          </AlertDialogContent>
        </AlertDialog>
      </Teleport>
    </ClientOnly>

    <ClientOnly>
      <AlertDialog v-model:open="commandDialogOpen">
        <AlertDialogOverlay class="!bg-transparent" />
        <AlertDialogContent class="scale-[1.5] after:content-none after:ring-2 after:ring-primary">
          <PlantInput v-if="commandDialogOpen" class="p-0" placeholder="Search Species" />
          <p class="mt-3 text-muted-foreground text-sm">Search 523,511 species<br /><span
              class="text-muted-foreground/50 text-xs">Source: GBIF, BIEN, Wikispecies</span></p>
        </AlertDialogContent>
      </AlertDialog>

    </ClientOnly>

  </div>

</template>

<script setup lang="ts">
const colorMode = useColorMode()
const dockStore = useDockStore()
const {activePanel} = storeToRefs(dockStore)
const route = useRoute()
import { useOnline, onKeyStroke } from '@vueuse/core'
import { useRootPageRef } from '@/lib/utils'
// console.log(colorMode.value)
import { AlertDialogOverlay } from 'radix-vue'

const { rootPageRef } = useRootPageRef()

const ready = ref(false)
onNuxtReady(() => {
  setTimeout(() => {
    // this is a workaround to prevent the footer from causing scrollbar on page load.
    ready.value = true
  }, 500)
})

const user = useSupabaseUser()
const online = useOnline()

const userStore = useUserStore()
const { plan } = storeToRefs(userStore)

onKeyStroke('Escape', () => {
  toggleCommandDialog(false)
})

const { Meta_K, Ctrl_K } = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.key === 'k' && (e.metaKey || e.ctrlKey))
      e.preventDefault()
  },
})

watch([Meta_K, Ctrl_K], (v) => {
  if (v[0] || v[1]){
    console.log('route.params.type', route.params.type)
    if(route.params.type !== 'team' && route.params.type !== 'project'){ 
      toggleCommandDialog(true)
    }
  }
})

watch(online, (val) => {
    let connection;
    if (!val === false) {
        connection = push.error({title: "You are offline", message: "Please check your internet connection and try again."})
    } else {
        connection = push.success({title: "You are online", message: "You are back online."})
        connection.clear()
        
    }
})

import {
  lightTheme,
  darkTheme,
  type NotivueTheme
} from 'notivue'

const mylightTheme: NotivueTheme = {
    ...lightTheme,
    // '--nv-warning-accent': "var(--destructive)"
}
const myDarkTheme: NotivueTheme = {
    ...darkTheme,
}

const uiStore = useUiStore()
const { menu, chatDialogOpen, windowWidth, showAlert, alertConfig, alertDialogDynamicComponent, commandDialogOpen, banners, reviewSideDrawerMode } = storeToRefs(uiStore)
const { toggleChatDialog, toggleCommandDialog, toggleAlert, toggleBanner } = uiStore


// Example of adding a banner on component mount
onMounted(() => {
  uiStore.addBanner({
    id: 'launch',
    message: 'We did it! 🎉 SuperSeeded is now open to the public.',
    type: 'info',
    dismissible: true,
    link: { url: '/about', text: 'Learn more' }
  })
})



</script>



<style lang="scss">

@media (min-width: 768px) {
  html, body {
    overflow-x: hidden;
    height: 100%;
    background-color: var(--bg-emerald-950);
    width: 100%;
    // overflow-y: hidden;
  }
}

.Notivue__content-title, .Notivue__content-message{
    font-family: "Inter", sans-serif;
}

.Notivue__content-title{
    font-weight: 500 !important;   
    width: 75%;
}

.Notivue__content-title, .Notivue__content-message, .Notivue__content-title *{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.notivue-custom {
  .Notivue__notification {
    width: 450px;
    border-radius: 0.35rem;
    border: none !important;
    box-shadow:
            0.8px 0.5px 1.7px -4px rgba(0, 0, 0, 0.036),
            1.9px 1.2px 4px -4px rgba(0, 0, 0, 0.052),
            3.5px 2.2px 7.2px -4px rgba(0, 0, 0, 0.064),
            5.8px 3.7px 11.9px -4px rgba(0, 0, 0, 0.076),
            9.5px 6.1px 19.6px -4px rgba(0, 0, 0, 0.088),
            16.6px 10.6px 34.2px -4px rgba(0, 0, 0, 0.104),
            36px 23px 74px -4px rgba(0, 0, 0, 0.14);
  }

  .Notivue__content {
    flex: 1;
    min-width: 0;
  }

  .Notivue__content-title,
  .Notivue__content-message {
    @apply truncate;
    max-width: 100%;
    font-size: 0.9em;
  }

  .Notivue__content-title {
    font-weight: 500 !important;
    width: 100%;
  }
}

html{
    /* prevent pull to refresh */
    overscroll-behavior: none;
}

body{
    /* prevent pull to refresh */
    overscroll-behavior: contain;
}

html, body {
    background-color: #f1f5f9;
    height: 100% !important;
}

/* Adjust these styles for the AlertDialog */
.v-dialog-overlay {
  z-index: 9999 !important;
}

.v-dialog-content {
  z-index: 10000 !important;
}

/* Add this to prevent scrolling when the alert is open */
body.v-dialog-open {
  overflow: hidden;
}


.no-close-button svg{
  display: none;
}

div[role="alertdialog"]:has(div:has(input)) {
  margin-top: -20vh !important;
}

</style>