import { useGeolocation } from '@vueuse/core'




interface BiosecurityResults {
  eppo: Array<{ hits: any[] }>;
  padil: Array<{ data: any[] }>;
}

export function useSearch() {
  const authStore = useAuthStore();
  const { setOpenverseAccessToken } = authStore;
  const { openverseAccessToken } = storeToRefs(authStore);

  const { search } = useMeiliSearch('gbif')
  const { search: searchEPPO } = useMeiliSearch('eppo')
  const meilisearch = useMeiliSearchRef()

  const results: Ref<BiosecurityResults | any> = ref(null)
  const fetchStatus = ref<'idle' | 'pending' | 'error'>('idle')

  const fetchSpeciesDataIndividually = async (data: string[], opts: {
    coords?: { latitude: number, longitude: number },
    limit?: number,
    distinct?: string,
    sort?: string[]
  } = {}) => {
    const { coords = { latitude: Infinity, longitude: Infinity }, limit = 1, distinct = 'species', sort = [] } = opts;
    
    fetchStatus.value = 'pending'
    results.value = []
    for (let d of data) {
      const { data: speciesData } = await useAsyncData(
        `botanical-names-${d}`,
        async () => {
          const searchOptions: any = { limit, distinct }
          if (coords.latitude !== Infinity && coords.longitude !== Infinity) {
            searchOptions.sort = [`_geoPoint(${coords.latitude}, ${coords.longitude}):asc`]
          }
          return await search(d, searchOptions)
        },
        {
          getCachedData: (key) => {
            // return null
            const nuxtApp = useNuxtApp()
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
          },
        }
      )
      results.value.push(speciesData.value?.hits || [])
    }
    
    fetchStatus.value = 'idle'
    return results.value
  }

  const fetchSpeciesDataFederated = async (data: string[], coords: any = { latitude: Infinity, longitude: Infinity }) => {
    fetchStatus.value = 'pending'
    const { data: speciesData } = await useAsyncData(
      `botanical-names-${data.join('-')}`,
      async () => {
        const searchOptions: any = {}
        if (coords.latitude !== Infinity && coords.longitude !== Infinity) {
          searchOptions.sort = [`_geoPoint(${coords.latitude}, ${coords.longitude}):asc`]
        }
        return await meilisearch.multiSearch({
          federation: { limit: 1 },
          queries: data.map(d => ({ indexUid: 'gbif', q: d, ...searchOptions })),
        })
      },
      {
        getCachedData: (key) => {
          const nuxtApp = useNuxtApp()
          return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        },
      }
    )
    results.value = speciesData.value?.hits || []

    fetchStatus.value = 'idle'
    return results.value
  }

  const fetchBiosecurityData = async (
    data: string[],
    filters: string[] = ['libtype=plant']
  ): Promise<BiosecurityResults> => {
    fetchStatus.value = "pending";

    const filtersArray = Array.isArray(filters) ? filters : [filters];

    const [eppoData, padilData] = await Promise.all([
      useAsyncData(
        `eppo-${data.join('-')}-${filtersArray.join('-')}`,
        async () => {
          const searchPromises = data.map(query => searchEPPO(query, {
            filter: filtersArray.join(' AND '),
            limit: 100
          }));
          const searchResults = await Promise.all(searchPromises);
          return searchResults.flat();
        },
        {
          getCachedData: (key) => {
            const nuxtApp = useNuxtApp();
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
          },
        }
      ),
      useAsyncData(
        `padil-${data.join('-')}`,
        async () => {
          const searchPromises = data.map(query => $fetch(`/api/bio/padil`, {
            method: 'POST',
            body: { searchQuery: query }
          }));
          const searchResults = await Promise.all(searchPromises);
          return searchResults.flat();
        },
        {
          getCachedData: (key) => {
            const nuxtApp = useNuxtApp();
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
          },
        }
      )
    ]);

    results.value = { eppo: eppoData.data.value, padil: padilData.data.value };
    console.log('padilData', padilData.data.value)
    fetchStatus.value = "idle";
    return results.value;
  };

  const fetchOpenverse = async (speciesNames: string[]) => {
    if (
      !openverseAccessToken.value?.key ||
      openverseAccessToken.value?.expiry < Date.now()
    ) {
      const ov_token = await $fetch("/api/auth/openverse", {
        method: "POST",
        headers: {
          ...useRequestHeaders(["cookie"]),
        },
      })
      setOpenverseAccessToken(ov_token.key, ov_token.expiry)
    }
    
    const apiKey = openverseAccessToken.value?.key;
    
    const openverseResults = ref<any[]>([]);

    const { data: openverseData, refresh } = await useAsyncData(
      `openverse-images-${speciesNames.join('-')}`,
      async () => {
        const requests = speciesNames.map((speciesName) =>
          $fetch(
            `https://api.openverse.org/v1/images/?q=${speciesName}&license=by,by-nd,by-sa,cc0,pdm`,
            {
              headers: {
                Authorization: `Bearer ${apiKey}`,
              },
            }
          )
        );
        const responses = await Promise.all(requests);
        return responses.flatMap(
          (response) => (response as { results: any[] }).results
        );
      }
    );

    openverseResults.value = openverseData.value || [];

    return {
      openverseResults,
      refreshOpenverse: refresh,
    };
  }

  return {
    results,
    fetchStatus,
    fetchSpeciesDataIndividually,
    fetchSpeciesDataFederated,
    fetchBiosecurityData,
    fetchOpenverse
  }
}