<template>
  <div v-motion-fade-visible v-for="banner in visibleBanners" :key="banner.id"
    :class="['flex w-full h-[60px] justify-center items-center p-3', getBannerClass(banner.type)]">
    <p class="relative z-20 text-sm select-none" :class="getBannerTextClass(banner.type)">
      {{ banner.message }}
      <NuxtLink v-if="banner.link" :to="banner.link.url" class="underline mx-2"
        :class="getBannerLinkClass(banner.type)">
        {{ banner.link.text }}
      </NuxtLink>
    </p>
    <Button v-if="banner.dismissible" variant="icon" class="ml-1" :class="getBannerButtonClass(banner.type)"
      @click="dismissBanner(banner.id)">
      <Icon name="mdi:close" />
    </Button>
  </div>
</template>

<script setup lang="ts">
const uiStore = useUiStore()
const { banners, dismissedBannerIds } = storeToRefs(uiStore)

const visibleBanners = computed(() =>
  banners.value.filter(banner => banner.show && !dismissedBannerIds.value.includes(banner.id))
)

const dismissBanner = (id: string) => {
  uiStore.removeBanner(id)
}

const getBannerClass = (type: string) => {
  switch (type) {
    case 'info': return 'bg-primary'
    case 'warning': return 'bg-warning'
    case 'error': return 'bg-destructive'
    case 'success': return 'bg-success'
    default: return 'bg-primary'
  }
}

const getBannerTextClass = (type: string) => {
  switch (type) {
    case 'info': return 'text-background/80'
    case 'warning': return 'text-warning-foreground'
    case 'error': return 'text-destructive-foreground'
    case 'success': return 'text-success-foreground'
    default: return 'text-background/80'
  }
}

const getBannerLinkClass = (type: string) => {
  switch (type) {
    case 'info': return 'text-background'
    case 'warning': return 'text-warning-foreground'
    case 'error': return 'text-destructive-foreground'
    case 'success': return 'text-success-foreground'
    default: return 'text-background'
  }
}

const getBannerButtonClass = (type: string) => {
  switch (type) {
    case 'info': return 'text-background'
    case 'warning': return 'text-warning-foreground'
    case 'error': return 'text-destructive-foreground'
    case 'success': return 'text-success-foreground'
    default: return 'text-background'
  }
}
</script>