import { defineStore } from 'pinia'
import update from 'lodash.update'
import merge from 'lodash.merge'  // Import lodash.merge

export const useDockStore = defineStore(
  "DockStore",
  () => {
    const panelState = ref({});
    const dragOver = ref(false);
    const panelDroppedOn = ref("");
    const panelProps = ref({});
    const activePanel = ref("");
    const includeDataInChat = ref(false);
    const selectedLayout = ref(null);
    const lastActivePanel = ref("");
    const startNewLayout = ref(false);
    const panelId_toolUid = ref({});
    const pendingPanels = ref([]);
    const switchToPanel = ref("");
    const saving = ref(false);
    const promptReplacePanel = ref(false);
    const panelToRefresh = ref([]);
    const availablePanels = ref<
      { panel_id: string; tool_uid: string; metadata: any }[]
    >([])

    const setPanelState = (p: any) => {
      panelState.value = p;
    };

    const setPromptReplacePanel = (p: boolean) => {
      promptReplacePanel.value = p;
    };

    const addPanelState = (p: any) => {
      panelState.value = { ...panelState.value, ...p };
    };

    const mergeData = (panel_id: string, newData: any) => (currentValue: any) => {
      return merge(panelState.value[panel_id], newData);
    };

    const updatePanelState = (path: string, updater: (value: any) => any) => {
      panelState.value = update(panelState.value, path, updater);
    };
    const clearPanelState = () => {
      panelState.value = {};
    };
    const removePanel = (panel_id: string) => {
      delete panelState.value[panel_id];
    };

    const setDragOver = (p: boolean) => {
      dragOver.value = p;
    };
    const setPanelDroppedOn = (p: string) => {
      panelDroppedOn.value = p;
    };

    const setPanelProps = (p: any) => {
      panelProps.value = p;
    };

    const addPanelProps = (p: any) => {
      panelProps.value = { ...panelProps.value, ...p };
    };

    const setPanelId_toolUid = (p: any) => {
      panelId_toolUid.value = { ...panelId_toolUid.value, ...p };
    };

    const deletePanelId_toolUid = () => {
      panelId_toolUid.value = {}
    };

    const setActivePanel = (p: any) => {
      if (p !== activePanel.value) {
        activePanel.value = p;
      }
    };

    const setLastActivePanel = (p: any) => {
      lastActivePanel.value = p;
    };

    const setIncludeDataInChat = (p: boolean) => {
      includeDataInChat.value = p;
    };

    const setAppData = (p: any, panel_name: string) => {
      console.log("setting app data", p, panel_name);
      try {
        if (!panelProps.value) throw new Error("panelProps is undefined");
        if (!panelProps.value[panel_name])
          throw new Error(`panelProps[${panel_name}] is undefined`);
        if (!panelProps.value[panel_name].data)
          throw new Error(`panelProps[${panel_name}].data is undefined`);

        // Ensure reactivity by creating a new object
        panelProps.value[panel_name].data = {
          ...panelProps.value[panel_name].data,
          app: p,
        };
      } catch (e) {
        console.log("error setting app data", e);
      }
    };

    const setSelectedLayout = (p: any) => {
      selectedLayout.value = p;
    };

    const setStartNewLayout = (p: boolean) => {
      startNewLayout.value = p;
    };

    const addPendingPanel = (panelData: any) => {
      pendingPanels.value.push(panelData);
    };

    const getPendingPanels = () => {
      const panels = [...pendingPanels.value];
      pendingPanels.value = [];
      return panels;
    };

    const setSwitchToPanel = (p: string) => {
      switchToPanel.value = p;
    };

    const deleteStatePanel = (panel_id: string) => {
      try {
        delete panelState.value[panel_id];
      } catch (error) {
        console.log("error deleting state panel", error);
      }
    };

    const setSaving = (p: boolean) => {
      saving.value = p;
    };

    const refreshPanel = (component_name: string) => {
      panelToRefresh.value = [...panelToRefresh.value, component_name]
    };

    const removePanelToRefresh = (component_name: string) => {
      panelToRefresh.value = panelToRefresh.value.filter(component_name => component_name !== component_name)
    }

    const clearPanelsToRefresh = () => {
      panelToRefresh.value = []
    }

    const cleanupPanelState = (splitLayoutPanels: string[]) => {
      const updatedPanelState = { ...panelState.value };
      
      for (const panelId in updatedPanelState) {
        // if (!splitLayoutPanels.includes(panelId) || updatedPanelState[panelId].data === undefined) {
        if (updatedPanelState[panelId].title === undefined) {
          delete updatedPanelState[panelId];
        }
        // if (!splitLayoutPanels.includes(panelId)) {
          // delete updatedPanelState[panelId];
        // }
      }

      panelState.value = updatedPanelState;
    };

    const setAvailablePanel = (data: {
      panel_id: string
      tool_uid: string
      metadata: any
    }) => {
      if (!Array.isArray(availablePanels.value)) {
        availablePanels.value = [];
      }
      const index = availablePanels.value.findIndex(
        (ap) => ap.panel_id === data.panel_id
      )
      if (index !== -1) {
        // Use Vue.set or spread operator to ensure reactivity
        availablePanels.value[index] = { ...data }
      } else {
        availablePanels.value = [...availablePanels.value, data]
      }
    }

    const removeAvailablePanel = (panel_id: string) => {
      if (Array.isArray(availablePanels.value)) {
        availablePanels.value = availablePanels.value.filter(
          (ap) => ap.panel_id !== panel_id
        )
      }
    }

    const removeAllAvailablePanels = () => {
      availablePanels.value = []
    }

    const updatePanelColumns = (panelId: string, columns: any[]) => {
      const panel = panelState.value[panelId];
      if (panel) {
        if (panel.output?.tables) {
          panel.output.tables.columns = columns;
        }
        if (panel.data?.output?.tables) {
          panel.data.output.tables.columns = columns;
        }
      }
      // Trigger reactivity
      panelState.value = { ...panelState.value };
    };

    const updatePanelRows = (panelId: string, rows: any[]) => {
      const panel = panelState.value[panelId];
      if (panel) {
        if (panel.output?.tables) {
          panel.output.tables.rows = rows;
        }
        if (panel.data?.output?.tables) {
          panel.data.output.tables.rows = rows;
        }
      }
      // Trigger reactivity
      panelState.value = { ...panelState.value };
    };

    return {
      panelState,
      setPanelState,
      addPanelState,
      dragOver,
      setDragOver,
      panelDroppedOn,
      setPanelDroppedOn,
      panelProps,
      setPanelProps,
      addPanelProps,
      activePanel,
      setActivePanel,
      setAppData,
      includeDataInChat,
      setIncludeDataInChat,
      selectedLayout,
      setSelectedLayout,
      lastActivePanel,
      setLastActivePanel,
      startNewLayout,
      setStartNewLayout,
      panelId_toolUid,
      setPanelId_toolUid,
      pendingPanels,
      addPendingPanel,
      getPendingPanels,
      deleteStatePanel,
      clearPanelState,
      mergeData,
      updatePanelState,
      setSwitchToPanel,
      switchToPanel,
      saving,
      setSaving,
      promptReplacePanel,
      setPromptReplacePanel,
      cleanupPanelState,
      removePanel,
      refreshPanel,
      panelToRefresh,
      removePanelToRefresh,
      availablePanels,
      setAvailablePanel,
      removeAvailablePanel,
      updatePanelColumns,
      updatePanelRows,
      clearPanelsToRefresh,
      removeAllAvailablePanels,
      deletePanelId_toolUid,
    };
  }, {
    persist: false
  }
)